import { EntityPickerDialog }             from "@/components";
import { PrimeVueEvent } from "@/model/common/PrimeVueTypes";
import { EntityPicker, EntityPickerType } from "@/model/Entity";
import { authStore }                      from "@/modules/auth/store";
import { SessionStorageEnum }             from "@/utils/SessionStorageEnum";
import { entityPickersService }           from "@services/entityPickers.service";
import { FilterMatchMode } from "primevue/api";
import { Options, Vue }                   from "vue-class-component";

import {
  PageHeader,
} from "@/components";

@Options({
  inheritAttrs: false,
  components: {
    EntityPickerDialog,
    PageHeader,
  },
  beforeRouteEnter(to, from, next) {
    if (from.name && !(from.name as string).startsWith('customers')) {
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_CUSTOMERS);
    }

    next();
  },
  beforeRouteLeave() {
    
    if (this.service?.cancelPendingRequests) {
      
      this.service.cancelPendingRequests();
    }
  },
})
export default class RubricPage extends Vue {
  
  get dt(): any {
    return this.$refs.dt;
  }

  get me() {
    return authStore.getters.me;
  }

  editPicker: EntityPicker = null;

  displayNewPicker: boolean = false;
  onUpdateVisible(value){
    if(!value){
      this.editPicker = null; 
    }
  }

  pickers: EntityPicker[] = null; 

  totalRecords = 0;

  readonly typeOptions = [
    { label: "Ritiro",    value: EntityPickerType.PICKUP },
    { label: "Consegna",  value: EntityPickerType.DROPOFF }
  ]

  filters: any = null;
  
  private initFilters() {
    this.filters = {
      name: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      type: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      address: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
    }; 
  }
  
  private getFilters() {
    return {
        ...this.dt.createLazyLoadEvent(),
        filters : this.filters
    }
  }
  
  onApplyFilter(filterCallback?: Function) {
    filterCallback && filterCallback();
    this.applyFilter();
  }

  public applyFilter() {
    this._loadPickers(this.getFilters());
  }
  
  clearFilters() {
    this.initFilters();
    this.applyFilter();
  }
  
  onPage(e: any): any {
    const pe = e as PrimeVueEvent;
    this._loadPickers(pe);
  }

  onSort(e: Event): any {
    const pe = e as PrimeVueEvent;
    this._loadPickers(pe);
  }

  showNewPicker() {
    this.editPicker = null; 
    this.displayNewPicker = true;
  }

  onEditPicker(data: EntityPicker) {
    this.editPicker = { ...data };
    this.displayNewPicker = true;
  }

  onDeletePicker(data: EntityPicker) {
    const type = data.type === EntityPickerType.DROPOFF 
      ? "consegna" 
      : "ritiro";

    const message = `Rimuovere il punto di ${type} selezionato ?`;

    this.$confirmMessage(message)
      .then(async response => {
        if (response) {
          
          this.$waitFor(
            async () => {
              await entityPickersService.remove(data);

              this._loadPickers();

              this.$successMessage(`Punto di ${type} eliminato`);
            },

            `Cancellazione punto di ${type} non riuscita`
          )
          
        }
      })
  }

  onSaved() {
    this._loadPickers();
  }

  private async _loadPickers(params?: PrimeVueEvent){
    params?.originalEvent && delete params.originalEvent;

    try {
      this.requestPending = true;

      const {
          filters = {},
          sortField,
          sortOrder
      } = params || {} as any;

      const p = {
          entity_id: this.me.id,
          sortField,
          sortOrder,
          per_page : params?.rows,
          page     : (params?.first ? params.first / params.rows : 0) + 1,
      } as any;

      if (filters && Object.keys(filters).length) {
          p.filters = {...filters};
      }

      const response = await entityPickersService.index(p);

      const { data, total } = response;

      this.pickers      = data;
      this.totalRecords = total;

    } catch (error: any) {
        console.error(error);
        this.$errorMessage("", "Caricamento punti di ritiro/consegna non riuscito");

    } finally {
      this.requestPending      = false;
    }
  }

  created() {
    this.initFilters();
  }

  mounted(){
    this.applyFilter();
  }
}